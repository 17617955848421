






import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";

@Component({
  components: { Page },
})
class Notifications extends Vue {}

export default Notifications;
